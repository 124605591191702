import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  Form,
  Input,
  Button,
  Col,
  notification,
  Row,
  Select,
  Tooltip,
  Icon,
} from 'antd';

import ShopStore from 'stores/ShopStore';
import CurrentUserStore from 'stores/CurrentUserStore';

import { RolesOptions } from 'stores/Classes/UserObject';
import { StyledPitstopModal } from './common/styled';
import FormInput from './common/FormInput';
import PhoneInput from './common/PhoneInput';
import FormSelect from './common/FormSelect';
import FormCheckbox from './common/FormCheckbox';

class AddUserForm extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    onSubmit: PropTypes.func,
    initialData: PropTypes.object,
    currentShopName: PropTypes.string,
    hideVin: PropTypes.bool,
    shops: PropTypes.array,
  };

  phoneInputRef = React.createRef();

  componentDidMount() {
    if (CurrentUserStore.user.role === 'admin' && !ShopStore.loaded) {
      ShopStore.getShops();
    }
  }

  handlePhoneChange = (e) => {
    const { form } = this.props;
    form.setFieldsValue({ phone: e.target.value });
  };


  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    const shops = Array.from(ShopStore.data.values());

    return (
      <Form layout="vertical">
        <Row gutter={[10, 10]}>
          <Col xs={12} sm={12}>
            <FormInput
              label="First Name"
              decorator={getFieldDecorator}
              fieldName="firstName"
              initValue={this.props.initialData?.firstName}
              validationRules={[
                { required: true, message: 'Please enter the First Name' },
              ]}
            />
          </Col>
          <Col xs={12} sm={12}>
            <FormInput
              label="Last Name"
              decorator={getFieldDecorator}
              fieldName="lastName"
              initValue={this.props.initialData?.lastName}
              validationRules={[
                { required: true, message: 'Please input the Last Name' },
              ]}
            />
          </Col>

        </Row>
        <Row gutter={[10, 10]}>
          <Col xs={12} sm={12}>
            <FormInput
              label="Email/Username"
              decorator={getFieldDecorator}
              fieldName="email"
              initValue={this.props.initialData?.email}
              validationRules={[
                { required: true, message: 'The email/username is required' },
              ]}
            />
          </Col>
          <Col xs={12} sm={12}>
            <PhoneInput
              label="Phone Number"
              decorator={getFieldDecorator}
              fieldName="phone"
              initValue={this.props.initialData?.phone}
              handlePhoneChange={this.handlePhoneChange}
              ref={this.phoneInputRef}
              validationRules={[
                {
                  pattern: /^\+1 \(\d{3}\) \d{3}-\d{4}$/,
                  message: 'Please enter a valid phone number',
                },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col xs={12} sm={12}>
            <FormSelect
              label="Fleet"
              decorator={getFieldDecorator}
              fieldName="fleet"
              initValue={this.props.initialData?.role}
              options={shops}
              validationRules={[
                { required: true, message: 'Please select a fleet' },
              ]}
              placeholder="Select a fleet"
              showSearch
              allowClear
              filterOption={(input, option) => {
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            />
          </Col>
          <Col xs={12} sm={12}>
            <FormSelect
              label="Role"
              decorator={getFieldDecorator}
              fieldName="role"
              initValue={this.props.initialData?.role}
              options={RolesOptions}
              validationRules={[
                { required: true, message: 'Please select a role' },
              ]}
              placeholder="Select a role"
            />
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col xs={12} sm={12}>
            <FormInput
              label={
                <span>
                  Set Password&nbsp;
                  <Tooltip title="If a username is used, the password can be edited from the user’s profile page if they forget it.">
                    <Icon type="info-circle" />
                  </Tooltip>
                </span>
              }
              decorator={getFieldDecorator}
              fieldName="password"
              type="password"
              initValue={this.props.initialData?.password}
              validationRules={[
                { required: true, message: 'The password is required' },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={[0, 10]}>
          <Col xs={24} sm={24} style={{ marginBottom: '0px', paddingBottom: '0px' }}>
            <FormCheckbox
              title="Send login credentials and instructions to the phone number provided"
              decorator={getFieldDecorator}
              fieldName="sendLoginCredentials"
              initValue={false}
            />
            {/* </Col>
          <Col xs={24} sm={24}> */}
            <FormCheckbox
              title="Print login credentials and instructions "
              decorator={getFieldDecorator}
              fieldName="printLoginCredentials"
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedAddUserForm = Form.create({
  name: 'add_user_with_username',
})(AddUserForm);

export default WrappedAddUserForm;