import { observe } from 'mobx';

export const initializeShopUsers = (UserStore, ShopStore, setStateCallback) => {
  const init = async () => {
    const shopUsers = await UserStore.fetchShopUsers(ShopStore.currentShop.id);

    const users = shopUsers
      .filter((user) => user.firstName || user.lastName)
      .map((user) => ({
        name: `${user.firstName} ${user.lastName}`,
        id: user.userId,
      }));

    setStateCallback({ users: users || [], shopUsers });
  };

  const shopChangeWatcher = observe(ShopStore.currentShop, 'id', () => {
    if (ShopStore.isAdmin()) return;
    return init();
  });

  return { init, shopChangeWatcher };
};