import styled from 'styled-components';
import { DatePicker, Form, InputNumber } from 'antd';

import { PitstopModal } from 'shared';

export const StyledPitstopModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      border-radius: 10px 10px 0 0;

      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-content {
      border-radius: 10px;
    }

    .ant-modal-close-x {
      color: #ffffff;
    } 
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 5px;
  width: 100%;
`;
export const StyledInputNumber = styled(InputNumber)`
  width: 100% !important;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100% !important;
`;

export const cancelButtonProps = {
  type: "danger",
  ghost: true,
  style: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#ff4d4f',
    boxShadow: 'none'
  }
}