import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { FormItem } from './styled'

const FormSelect = React.memo(({ label, decorator, fieldName, initValue = [], placeholder = '', options = [], validationRules = [], ...rest }) => {
  return (
    <FormItem
      label={label}
    >
      {decorator(fieldName, {
        initialValue: initValue,
        rules: validationRules,
      })(
        <Select
          placeholder={placeholder}
          style={{ width: '100%' }}
          allowClear
          {...rest}
        >
          {options.map(({ id, name }) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      )}
    </FormItem>
  );
});

FormSelect.propTypes = {
  label: PropTypes.string,
  decorator: PropTypes.func,
  fieldName: PropTypes.string,
  initValue: PropTypes.string,
  validationMsg: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string
};

export default FormSelect
