import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input } from 'antd';
import InputMask from 'react-input-mask';

import { FormItem } from './styled'

const PhoneInput = React.memo(
  ({
    label,
    decorator,
    fieldName,
    initValue,
    handlePhoneChange,
    ref,
    validationRules = [],
  }) => (
    <FormItem label={label}>
      {decorator(fieldName, {
        initialValue: initValue,
        rules: validationRules,
      })(
        <InputMask
          id="phoneWithMask"
          mask="+1 (999) 999-9999"
          maskChar={null}
          onChange={handlePhoneChange}
        >
          {(inputProps) => <Input {...inputProps} ref={ref} />}
        </InputMask>
      )}
    </FormItem>
  )
);

PhoneInput.propTypes = {
  label: PropTypes.string,
  decorator: PropTypes.func,
  fieldName: PropTypes.string,
  initValue: PropTypes.string,
  validationMsg: PropTypes.string,
  handlePhoneChange: PropTypes.func,
  ref: PropTypes.object,
};

export default PhoneInput;

