export const handleDuplicateEmailError = (error, emailToCheck) => {
  let message = error.message;

  if (!error?.message || !emailToCheck) {
    return message;
  }

  const isEmailUsedError = error.message.includes('is already used');

  if (!isEmailUsedError) {
    return message;
  }

  if (error.message.includes('@username.pitstop')) {
    const username = error.message
      .split(' ')
      .find(part => part.includes('@username.pitstop'))
      .split('@')[0];
    return `The username ${username} is already in use. Please try another one.`;
  }

  return `User with email ${emailToCheck} already exists. Please try another one.`;
};