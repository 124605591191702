import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input } from 'antd';
import { FormItem, StyledInputNumber } from './styled';

const FormInput = React.memo(({ label, decorator, fieldName, type = 'text', onChange = () => { }, initValue, password = false, validationRules = [], inputProps, ...rest }) => {

  const getInputComponent = () => {
    switch (type) {
      case 'password':
        return <Input.Password {...rest} />;
      case 'number':
        return <StyledInputNumber {...rest} {...inputProps} />;
      case 'textarea':
        return <Input.TextArea {...rest} />;
      default:
        return <Input {...rest} />;
    }
  };
  return (
    <FormItem label={label}>
      {decorator(fieldName, {
        initialValue: initValue,
        rules: validationRules,
        onChange: onChange
      })(getInputComponent())}
    </FormItem>
  );
});

FormInput.propTypes = {
  label: PropTypes.string,
  decorator: PropTypes.func,
  fieldName: PropTypes.string,
  initValue: PropTypes.string,
  validationMsg: PropTypes.string,
  password: PropTypes.bool,
};

export default FormInput;

