import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Row, Col, notification } from 'antd';
import { toJS } from 'mobx';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';

import { StyledPitstopModal } from './common/styled';
import { ShopStore, UserStore, AppStore } from 'stores';
import FormSelect from './common/FormSelect';
import TimePickerDropdown from './common/TimePickerDropdown';

import { initializeShopUsers } from './common/shopUsersUtil';
import { cancelButtonProps } from './common/styled';

const WEEK_DAYS = [
  { name: 'Sunday', id: 0 },
  { name: 'Monday', id: 1 },
  { name: 'Tuesday', id: 2 },
  { name: 'Wednesday', id: 3 },
  { name: 'Thursday', id: 4 },
  { name: 'Friday', id: 5 },
  { name: 'Saturday', id: 6 }
];

const TIME_ZONE = 'America/Toronto';

const StyledP = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
`

class AddEmailReportAlertModal extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    form: PropTypes.object.isRequired
  };

  state = {
    shopUsers: [],
    users: []
  };

  componentDidMount() {
    this.initializeUsers();
  }

  componentWillUnmount() {
    if (this.shopChangeWatcher) {
      this.shopChangeWatcher();
    }
  }

  initializeUsers = () => {
    const { init, shopChangeWatcher } = initializeShopUsers(
      UserStore,
      ShopStore,
      this.handleUsersUpdate
    );

    this.shopChangeWatcher = shopChangeWatcher;
    init();
  };

  handleUsersUpdate = (usersObj) => {
    this.setState({
      shopUsers: usersObj.shopUsers,
      users: usersObj.users
    });
  };

  handleOk = () => {
    this.props.form.validateFields(this.handleFormSubmit);
  };

  handleFormSubmit = async (err, values) => {
    if (err) {
      console.error('Form validation error:', err);
      AppStore.addError('Sorry, there was an error saving your changes. Please try again.');
      return;
    }

    try {
      await this.processFormSubmission(values);
      this.handleSuccessfulSubmission();
    } catch (error) {
      console.error('Form submission error:', error);
      AppStore.addError('Failed to save changes. Please try again.');
    }
  };

  processFormSubmission = async (values) => {
    const { users, days, time } = values;
    const dateObject = this.createDateObject(time, days);
    const diagnosticNotifications = this.getDiagnosticNotifications();

    for (const userId of users) {
      await this.processUserNotification(userId, diagnosticNotifications, dateObject);
    }
  };

  createDateObject = (time, days) => {
    const timeObj = moment(time, 'h:mma');
    return ShopStore.generateDateObject(moment(), days, timeObj);
  };

  getDiagnosticNotifications = () => {
    const shopNotifications = toJS(ShopStore.shopNotifications.get(ShopStore.currentShop.id));
    return _.chain(shopNotifications)
      .get('data.emailConfiguration')
      .filter((a) => a.email_report_type === 'DiagnosticEmail')
      .value();
  };

  processUserNotification = async (userId, diagnosticNotifications, dateObject) => {
    const user = this.getUserById(userId);
    const userReports = diagnosticNotifications.filter((a) => a.id_user === userId);

    if (_.isEmpty(userReports)) {
      await this.createNewNotification(user, diagnosticNotifications, dateObject);
    } else {
      await this.updateExistingNotification(userReports[0], user, dateObject);
    }
  };

  getUserById = (userId) => {
    const user = this.state.shopUsers.find((u) => u.userId === userId);
    return { id: user.userId, ...user };
  };

  createNewNotification = async (user, diagnosticNotifications, dateObject) => {
    await ShopStore.checkAndRemoveEmailBasedNotificationsForUser(diagnosticNotifications, user);
    await ShopStore.sendEmailVehicleHealthReportConfig(
      ShopStore.currentShop.id,
      [user.id],
      dateObject,
      TIME_ZONE
    );
  };

  updateExistingNotification = async (report, user, dateObject) => {
    await ShopStore.updateEmailVehicleHealthReportConfig(
      report.id,
      ShopStore.currentShop.id,
      [user.id],
      dateObject,
      TIME_ZONE
    );
  };

  handleSuccessfulSubmission = async () => {
    notification.success({
      message: 'Weekly Report Scheduled',
      description: 'Continue scheduling reports or close the modal'
    });

    this.props.form.resetFields();
    await ShopStore.getShopNotifications(ShopStore.currentShop.id, true);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <StyledPitstopModal
        id={this.props.id}
        title="Add Weekly Maintenance Report Schedule"
        width={570}
        okText="Add Schedule"
        closable
        isManualClose
        onOk={this.handleOk}
        onCancel={() => { }}
        cancelButtonProps={cancelButtonProps}
      >
        <StyledP>
          A maintenance report email will be sent automatically on the day of the week and time you select.
        </StyledP>

        <Form layout="vertical">
          <Row gutter={24}>
            <Col xs={12} sm={12}>
              <FormSelect
                label="Select User(s)"
                mode="multiple"
                decorator={getFieldDecorator}
                fieldName="users"
                options={this.state.users}
                validationRules={[{ required: true, message: 'Please select User(s)' }]}
              />
            </Col>
            <Col xs={12} sm={12}>
              <FormSelect
                label="Run every"
                decorator={getFieldDecorator}
                fieldName="days"
                options={WEEK_DAYS}
                validationRules={[{ required: true, message: 'Please select a day of the week' }]}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12} sm={12}>
              <TimePickerDropdown
                label="Run At"
                decorator={getFieldDecorator}
                fieldName="time"
                validationRules={[
                  { required: true, message: 'Please select a time to send the weekly report' }
                ]}
              />
            </Col>
          </Row>
        </Form>
      </StyledPitstopModal>
    );
  }
}

export default observer(
  Form.create({
    name: 'add_email_report_alert'
  })(AddEmailReportAlertModal)
);