import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import {
  Form,
  Input,
  Button,
  Col,
  notification,
  Row,
  Select,
  Tooltip,
  Icon,
} from 'antd';

import CurrentUserStore from 'stores/CurrentUserStore';
import UserStore from 'stores/UserStore';
import { Logger } from 'stores/Classes';

import { RolesOptions, ShopRolesApi } from 'stores/Classes/UserObject';
import { cancelButtonProps, StyledPitstopModal } from './common/styled';
import FormInput from './common/FormInput';
import PhoneInput from './common/PhoneInput';
import FormSelect from './common/FormSelect';
import WrappedAddUserForm from './AddUserForm';
import { handleDuplicateEmailError } from './common/utils';
import { AppStore, ShopStore } from 'stores';
import PrintLoginCredentialsButton from 'components/Users/PrintLoginCredentialsButton';

export const HidePrintButton = styled.div`
  display: none;
`;

const shopApiRoles = {
  customer: ShopRolesApi.driver,
  dealership: ShopRolesApi.fleetManager
}

class AddUserModal extends Component {
  constructor(props) {
    super(props);
    this.formRef = null;
  }

  userObj = null;
  printLoginCredentialsButtonRef = null;

  handleOk = () => {
    if (!this.formRef) return;
    this.formRef.validateFields(this.handleFormSubmit);
  };

  handleFormSubmit = async (err, values) => {

    const { onAddUsers } = this.props;

    if (err) return;

    const user = this.createUserObject(values);

    try {
      const userObj = await this.addUserToShop(user);

      notification.success({
        message: 'User added',
        description: (
          <>
            <p>Continue adding users or close the modal.</p>
            <HidePrintButton>
              <PrintLoginCredentialsButton
                ref={(ref) => (this.printLoginCredentialsButtonRef = ref)}
                label="Print login credentials & instructions"
                type="link"
                users={[userObj]}
              />
            </HidePrintButton>
          </>
        )
      });

      if (onAddUsers) {
        onAddUsers();
      }
      this.formRef.resetFields();
      this.formRef.setFieldsValue({ phone: '' });

      if (values.printLoginCredentials) {
        await this.printLoginCredentialsButtonRef.handlePrint();
      }
    } catch (error) {
      Logger.error(error);
      this.handleAddUserError(error, user.email);
    }
  };

  createUserObject = (values) => {
    const { firstName, lastName, email, fleet, phone, role, password, sendLoginCredentials } = values;
    const { inviterId } = this.props

    return {
      shopId: fleet,
      inviterId: inviterId,
      firstName,
      lastName,
      email,
      username: email,
      phone,
      password,
      role,
      shopRole: shopApiRoles[role] || role,
      isSendCredentialsByPhone: sendLoginCredentials
    };
  };

  addUserToShop = async (user) => {
    return await UserStore.addUserToShop(user);
  };

  handleAddUserError = (error, email) => {
    const message = handleDuplicateEmailError(error, email);
    AppStore.addError(message);
  };

  render() {
    const { id } = this.props;

    return (
      <StyledPitstopModal
        id={id}
        title="Add User"
        width={590}
        okText="Add User"
        closable
        isManualClose
        onOk={this.handleOk}
        onCancel={() => { }}
        cancelButtonProps={cancelButtonProps}
      >
        <WrappedAddUserForm
          wrappedComponentRef={(instance) => {
            this.formRef = instance ? instance.props.form : null;
          }}
        />

      </StyledPitstopModal>
    );
  }
}

export default observer(AddUserModal);

