import React, { useState } from 'react';
import { Select, Tag } from 'antd';

import './SelectPriorities.css';

const Option = Select.Option;

const items = [
  { value: 'Critical', color: 'red' },
  { value: 'Major', color: 'orange' },
  { value: 'Minor', color: 'gold' },
];

const SelectPriorities = ({ value, onChange }) => {
  const priorities = [
    { label: 'Critical', value: 'critical', color: '#FF4D4F' },
    { label: 'Major', value: 'major', color: '#FAAD14' },
    { label: 'Minor', value: 'minor', color: '#52C41A' },
  ];

  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      className="custom-multi-select"
      value={value}
      onChange={onChange}
    >

      {priorities.map(({ label, value, color }) => (
        <Option key={value} title={label} value={value} className={`option-${value} custom-option-${value} `}>
          <span className="custom-text">{label}</span>
        </Option>
      ))}
    </Select>
  );
};

export default SelectPriorities;