import React from 'react';
import PropTypes from 'prop-types';
import { Col, Select } from 'antd';
import { FormItem } from './styled'

const FormMultySelect = React.memo((props) => {
  const { label, component, decorator, fieldName, initValue, validationRules } = props;
  return (
    <FormItem
      label={label}
    >
      {decorator(fieldName, {
        initialValue: initValue,
        rules: validationRules,
      })(
        component
      )}
    </FormItem>
  );
});

export default FormMultySelect
