import React from 'react';
import { Select } from 'antd';

import { FormItem } from './styled'

const { Option } = Select;

const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const displayHour = hour % 12 === 0 ? 12 : hour % 12;
      const period = hour < 12 ? 'am' : 'pm';
      const formattedTime = `${displayHour}:${minute.toString().padStart(2, '0')}${period}`;
      times.push(formattedTime);
    }
  }
  return times;
};

const TimePickerDropdown = ({ label, decorator, fieldName, initValue, validationRules = [] }) => {
  const timeOptions = generateTimeOptions();

  return (
    <FormItem
      label={label}
    >
      {decorator(fieldName, {
        initialValue: initValue,
        rules: validationRules,
      })(
        <Select
          style={{ width: '100%' }}
        >
          {timeOptions.map((time) => (
            <Option key={time} value={time}>
              {time}
            </Option>
          ))}
        </Select>
      )}
    </FormItem>
  );
};

export default TimePickerDropdown;
